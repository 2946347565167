html {
  font-size: 16px;
}

@media (width < 900px) {
  html {
    font-size: 14px;
  }
}
h1 {
  overflow-wrap: break-word;
}

body {
  margin: 0;
  color: white;
  background-color: black;
  font-family: "Chewy";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../public/images/bg.svg");
  background-size: cover; /* 전체 배경으로 설정 */
  background-repeat: no-repeat; /* 이미지 반복 방지 */
  background-attachment: fixed; /* 스크롤 시 배경 이미지 고정 */
  background-position: center; /* 배경 이미지 가운데 정렬 */
}

a {
  text-decoration: none;
  color: inherit;
}

a:visited {
  color: inherit; /* 혹은 원하는 색상으로 지정 */
}

span {
  line-height: 1.5;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* @font-face {
  font-family: "Sausages";
  src: url("/public/fonts/Sausages Regular 400.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
} */
