@import url("https://fonts.cdnfonts.com/css/suez-one");

.Community {
  display: flex;
  flex-direction: column;
  background-color: #000;
  color: white;
  text-align: center;
  justify-content: space-between;
  padding: 2rem;
}

.Community-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 180px;
  margin: 48px 0;
}

.Community-title {
  font-size: 52px;
  font-weight: 500;
  margin: 0;
}
.Community-start {
  font-size: 3.2rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Community-start-icon {
  width: 7.9rem;
}

.Community-iconbtns {
  margin: 3rem 0 1rem 0;
}

.Community-iconbtns a {
  display: flex;
}

.Community-iconbtns a:not(:last-child) {
  margin-right: 3rem;
  max-width: 50%;
}

.Community-iconbtns img {
  width: 50px;
  margin: 0 12px;
  animation: tada 3s infinite;
}

.Community-iconbtns img:hover {
  animation: none;
}

.Community-content {
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.2rem;
  margin-bottom: 2rem;
}

.mockup {
  animation: puls 2s infinite;
}

@keyframes puls {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@media (width < 900px) {
  .Community-container {
    flex-direction: column;
    gap: 0px;
  }

  .Community-iconbtns {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Community-iconbtns a:not(:last-child) {
    margin: 0;
  }
}
