.Tokenomics {
  display: flex;
  justify-content: center;
  padding: 4rem 0;
  margin: 0;
}
.Tokenomics-container {
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  width: 100%;
  align-items: center;
}
.Tokenomics-title {
  color: #c48252;
  font-size: 4.2rem;
  font-weight: 500;
  margin: 0;
}

.Tokenomics-subtitle {
  font-size: 42px;
  font-weight: 600;
  color: black;
  margin: 0;
}
.Tokenomics-divider {
  border: 1px #fff solid;
  margin: 2rem 0;
  width: 75%;
}
.Tokenomics-subcontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  perspective: 1000px;
}

.flex-container {
  display: flex;
}

.flex-direction-col {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-20 {
  gap: 20px;
}

.grid-col-1fr {
  display: grid;
  grid-template-columns: 475px 356px;
  row-gap: 120px;
  column-gap: 40px;
  margin: 40px 0;
  justify-items: center;
}

.Tokenomics-subcontainer img {
  border-radius: 5%;
  height: 300px;
  transition: transform 0.5s;
}

.Tokenomics-subcontent {
  color: #000;
  font-size: 22px;
  font-weight: 500;
}

.text-left {
  text-align: left;
}

.grid-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  margin: 4rem 0;
}

h1 span {
  color: rgb(209, 70, 36);
}
.Tokenomics-content {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
}

.Tokenomics-details {
  font-size: 32px;
  font-weight: 600;
}

.Tokenomics-pepe {
  width: 34.08rem;
}

.showRight {
  animation: slideInFromRight 0.5s ease-out forwards;
}

.showBottom {
  animation: slideInFromBottom 0.5s ease-out forwards;
}

.pie-chart-container {
  width: 100%;
  height: 500px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (width < 900px) {
  .Tokenomics-container {
    max-width: 100%;
    padding: 0;
  }

  .flex-container {
    flex-direction: column;
  }

  .Tokenomics-details {
    font-size: 24px;
    text-align: center;
  }

  .grid-col-1fr {
    grid-template-columns: 0.5fr;
    grid-gap: 4rem;
    justify-items: center;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
