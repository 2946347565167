.Header {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: space-between;
  gap: 10rem;
  font-weight: 500;
  padding: 1rem 2rem;
  z-index: 1;
}

.Header.scrolled {
  background-color: rgba(0, 0, 0, 0.8); /* 스크롤 시 배경색 */
}

.Header-navbar-start {
  display: flex;
  align-items: center;
  font-weight: 400;
}
.Header-navbar-start-icon {
  width: 6.64rem;
}
.Header-navcontainer {
  display: flex;
}
.Header-navbar-center {
  display: flex;
  align-items: center;
}

.Header-navbar-center :where(li) {
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  font-size: 1rem;
}
.Header-navbar-end {
  display: flex;
  gap: 1rem;
  cursor: pointer;
  align-items: center;
}
.Header-navbar-btn {
  display: flex;
  align-items: center;
  font-size: 18px;
  background-color: white;
  color: black;
  padding: 12px;
  border-radius: 0.5rem;
  text-align: center;
  font-weight: 400;
}

.Header-navbar-btn:hover {
  transform: scale(110%);
}
.Header-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: max-content;
}

.Header-navbar-item {
  display: flex;
  align-items: center;
  font-size: 18px;
  white-space: pre;
}

.Header-navbar-item:hover {
  font-weight: 700;
}

.Header-navbar-Link {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin: 0rem 2rem;
}
.Header-end-menu {
  display: none;
}

@media (max-width: 600px) {
  .Header-navbar-Link {
    margin: 0 !important;
  }

  .Header-navbar-btn {
    padding: 0.5rem 1rem !important;
    height: 25px;
  }
}
@media (max-width: 900px) {
  .Header {
    justify-content: space-between;
  }

  .Header-menu {
    position: absolute;
    top: 7rem;
    right: 0;
    overflow: hidden;
    flex-direction: column;
    transition: 0.3s ease-in-out;
    transform: scale(0);
    transform-origin: top;
  }
  .Header-menu-open {
    height: max-content;
    transform: scale(1);
  }
  .Header-end-menu {
    display: flex;
    justify-self: flex-end;
    align-self: center;
    padding: 0 1rem;
  }
}
