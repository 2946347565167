.roadmap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 4rem;
}

.roadmap-title {
  font-size: 4.2rem;
  font-weight: 500;
  color: #c48252;
  text-align: center;
  width: 100%;
  margin-bottom: 2rem;
}

.roadmap-line {
  position: absolute;
  width: 4px;
  background-color: #ccc;
  top: calc(15rem + 2rem);
  bottom: 0;
  left: 30%;
  transform: translateX(-50%);
}

.roadmap-line-colored {
  background-color: #cfcd2d; /* brown color */
  width: 100%;
  height: 0;
  transition: height 0.1s ease-out;
}

.roadmap-item {
  position: relative;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  width: 30%;
  margin: 64px 0;
}

.left-start {
  transform: translateX(-100%);
}

.right-start {
  transform: translateX(100%);
}

.roadmap-item.slide-in {
  opacity: 1;
  transform: translateX(0);
}

.roadmap-item-content {
  background-color: #fff;
  padding: 20px;
  color: #000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-weight: 500;
  border-radius: 8px;
}

.scrolling-horse {
  position: absolute;
  left: 30%;
  width: 4rem;
  height: auto;
  z-index: 10;
  transition: top 0.1s ease-out; /* Smooth movement */
}

@media (width < 900px) {
  .roadmap-item {
    width: 50%;
  }

  .roadmap-line {
    left: 10%;
  }

  .scrolling-horse {
    left: 10%;
  }
}
