.Main {
  overflow: hidden;
}

.Main-images {
  display: flex;
  justify-content: center;
}

.titlemodel {
  padding-bottom: calc(40 / 1920 * 100vw);
  background: url("/public/images/title-underline.svg") no-repeat bottom center;
  background-size: auto calc(60 / 1920 * 100vw);
  text-align: center;
}

.scroll-to-top {
  display: flex;
  position: fixed;
  right: 48px;
  bottom: 48px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}

@media (width < 1100px) {
  .Main-images {
    display: flex;
    justify-content: center;
  }

  .Main-images img {
    width: 200px;
  }

  .titlemodel {
    padding-bottom: calc(45 / 850 * 100vw);
    background-size: auto calc(60 / 850 * 100vw);
  }
}
